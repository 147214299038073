import React from "react";
import Layout from "../components/Layout";

export default function Impressum({ location }) {
  return (
    <Layout
      location={location}
      title={"Gartenbau Dannenfelser | Impressum"}
      description={""}
    >
      <h1>Impressum</h1>

      <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
      <p>
        Udo Dannenfelser GmbH
        <br />
        Im Wert 5
        <br />
        67591 Mörstadt
      </p>

      <p>
        <strong>Vertreten durch:</strong>
        <br />
        Udo Dannenfelser
      </p>

      <h2>Kontakt</h2>
      <p>
        Telefon: +49 06247-991701
        <br />
        Telefax: +49 06247-991702
        <br />
        E-Mail: verwaltung@gartenbau-dannenfelser.de
      </p>

      <h2>Umsatzsteuer-ID</h2>
      <p>
        Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a
        Umsatzsteuergesetz:
        <br />
        DE 268 495 136
      </p>

      <h2>
        Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
      </h2>
      <p>
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
        vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>

      <p>
        Quelle:{" "}
        <a href="https://www.e-recht24.de/impressum-generator.html">
          https://www.e-recht24.de/impressum-generator.html
        </a>
      </p>
    </Layout>
  );
}
